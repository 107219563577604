* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  // DO NOT ADD overflow-x: hidden here. It will break the position sticky option in other components
}
body > * {
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}
